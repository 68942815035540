<template>
	<div
		class="vacancy"
		:class="{
			'vacancy-favorite': isFavoritePage,
			'vacancy--position-sidebar': isPositionSidebar,
			'card-job': isLandingRole
		}"
	>
		<div class="vacancy__tags">
			<div
				v-if="vacancy.type_employment"
				class="vacancy-tag"
			>
				{{ typeEmploymentFormat(vacancy.type_employment) }}
			</div>
			<div
				v-if="vacancy.city"
				class="vacancy-tag"
			>
				{{ vacancy.city }}
			</div>
		</div>
		<div class="vacancy__informations">
			<div class="cell">
				<span
					class="vacancy__title"
					:class="{ 'vacancy__title--is-favorite': isFavoritePage }"
				>
					<router-link
						:to="`/vacancies/${vacancy.id}`"
						:target="isLandingRole ? '_blank' : '_self'"
						class="vacancy__link"
					><span>{{ vacancy.name }}</span></router-link>
				</span>
				<h5 class="vacancy__subtitle">
					{{ vacancy.company.company_name }}
				</h5>
			</div>
			<div
				v-if="!isFavoritePage"
				class="cell"
			>
				<div
					v-if="vacancy.salary_from || vacancy.salary_before"
					class="vacancy__salary"
				>
					{{ culcSalary(vacancy.salary_from, vacancy.salary_before) }}
				</div>
			</div>
		</div>
		<div
			v-if="vacancy.duties"
			ref="duties"
			class="vacancy__duties"
			:class="{ 'vacancy__duties--is-favorite': isFavoritePage }"
			v-text="$options.filters.dutiesFormat(vacancy.duties)"
		/>
		<div
			v-if="!isRecruiter"
			class="button-group"
		>
			<router-link
				v-if="isLandingRole"
				class="button button-hollow"
				:to="`/vacancies/${vacancy.id}`"
				target="_blank"
			>
				Откликнуться
			</router-link>
			<button
				v-else
				id="btnRespondToVacancy2"
				class="button blue"
				@click.stop.prevent="eventYandexRespond(vacancy)"
			>
				Откликнуться
			</button>
			<div
				v-if="isLogged"
				class="like-item"
				:class="{ active: isFavorite || isFavoritePage }"
				@click="addFavoriteVacancy(vacancy.id)"
			>
				<div class="like-icon" />
				<div
					v-if="isFavoriteVacancy && !isFavoritePage"
					class="like-title"
				>
					В избранном
				</div>
				<div
					v-if="!isFavoriteVacancy && !isFavoritePage"
					class="like-title"
				>
					В избранное
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
    props: {
        vacancy: {
            type: Object,
            default: null
        },
        isFavoritePage: {
            type: Boolean,
            default: false
        },
        isFavoriteVacancy: {
            type: Boolean,
            default: false
        },
        isPositionSidebar: {
            type: Boolean,
            default: false
        },
        isFavorite: {
            type: Boolean,
            default: false
        },
        isLandingRole: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            isRecruiter: 'isRecruiter'
        }),
        ...mapGetters('cnst', {
            employments: 'getEmployments',
        }),
    },
    methods: {
        ...mapActions('user', ['addFavoriteVacancy']),
        typeEmploymentFormat(type) {
            return this.employments.find(x => x.slug === type).value
        },
        eventYandexRespond() {
            if((window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru') && this.$metrika) {
                this.$metrika.reachGoal('click_response_vacancy')
            }
            if (this.isLogged) {
                this.isCheckingResponse = true
                if (this.$store.state.user.responses) {
                    if (this.checkLimitResponses(this.$store.state.user.responses)) this.modalOpen('reqVacancy', this.vacancy)
                    else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                    this.isCheckingResponse = false
                }
                else {
                    this.$store.dispatch('user/getAllResponses').then(result => {
                        if (this.checkLimitResponses(result)) this.modalOpen('reqVacancy', this.vacancy)
                        else this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                        this.isCheckingResponse = false
                    })
                }
            } else {
                this.modalOpen('signIn')
            }
        },
        checkLimitResponses(responses) {
            // FIXME: заменить на значение из глобальных настроек сайта
            const limit = 1
            const totalResponse = responses.reduce((accum, current) => {
                if (current.vacancy.id === this.vacancy.id) return accum += 1
                else return accum
            }, 0)

            return totalResponse < limit;
        },
        culcSalary(from, before) {
            let intl = new Intl.NumberFormat('en-En');
            return (
                (from ? `от ${intl.format(from)} ₽ ` : '') +
                (before ? `до ${intl.format(before)} ₽` : '')
            );
        }
    }
};
</script>

<style lang="less" scoped>
.vacancy {
    // margin: 0 0px 5px;
    padding: 25px 30px;
    border-radius: 6px;
    // background-color: white;
    cursor: pointer;
    .transition;
    &-favorite {
        position: relative;
        width: 33.33%;
        padding: 0 25px 45px 25px;
        margin: 0;
        @media @tablet-end {
            width: 50%;
            margin-bottom: 0;
        }
        @media @mobile-end {
            width: 100%;
        }
        &:after {
            @media @mobile-end {
                position: absolute;
                content: "";
                bottom: -1px;
                left: -1000px;
                display: block;
                width: 3000px;
                height: 1px;
                background: #e6ecf4;
                opacity: 1;
            }
        }
    }
    &__salary {
        padding-top: 6px;
        font-size: 16px;
        @media @mobile-end{
            margin-bottom: 15px;
            padding-top: 0;
        }
    }
    &__link {
        font-family: "Merriweather", serif;
        border-bottom: 1px solid rgb(204, 204, 204);
        font-size: 18px;
        font-weight: 700;
        color: rgb(0, 0, 0);
    }
    &--position-sidebar {
        // border: 1px solid #d6dde7;
        margin-bottom: 0;
        padding-top: 30px;
        .vacancy__duties {
            @media @tablet-end {
                min-height: 0;
            }
        }
        .vacancy-tag {
            border: 1px solid rgb(214, 221, 231);
        }
        .vacancy__link {
            font-family: "Merriweather", serif;
            font-size: 18px;
            font-weight: 700;
            color: rgb(0, 0, 0);
            border-bottom: 1px solid rgb(204, 204, 204);
        }
    }
    @media @tablet-end {
        margin-bottom: 0;
    }
    &:hover {
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.08);
    }
    &-tags {
        padding-bottom: 15px;
        @media @tablet-end {
            padding-bottom: 16px;
        }
    }
    &-tag {
        margin-bottom: 15px;
        display: inline-block;
        padding: 6px 17px;
        border-radius: 5px;
        font-size: 13px;
        background: @color-light-blue;
        &:not(:last-child) {
            margin-right: 10px;
        }
    }
    .cell {
        &:nth-child(1) {
            width: 66.66%;
            @media @mobile-end {
                width: 100%;
            }
        }
        &:nth-child(2) {
            width: 33.33%;
            text-align: right;
            @media @mobile-end {
                width: 100%;
                text-align: left;
            }
        }
    }
    .vacancy__duties {
        margin-bottom: 20px;
        color: #999;
        font-size: 16px;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 66px;
        max-height: 67px;
        &--is-favorite {
            margin-bottom: 20px;
            @media @mobile-end {
                min-height: 0;
            }
        }
    }
    .button-group {
        .button {
            margin-right: 25px;
            @media @oldy-end {
                margin-right: 15px;
            }
        }
    }
    &__subtitle {
        margin-bottom: 20px;
        font-size: 16px;
        font-weight: 400;
        @media @mobile-end {
            margin-bottom: 15px;
        }
    }
    &__title {
        margin-bottom: 19px;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        height: 55px;
        max-height: 55px;
        &--is-favorite {
            .font-title;
            font-size: 18px;
            font-weight: 700;
            color: @color-text;
            &:hover {
                a {
                    color: initial;
                    border-color: transparent;
                }
                span {
                    border-color: transparent;
                }
            }
            span {
                border-bottom: 1px solid #ccc;
                .transition;
            }
        }
    }
}
.like-item {
    display: inline-block;
    vertical-align: middle;
    cursor: pointer;
    &:hover {
        .like-icon {
            opacity: 1;
        }
        .like-title {
            color: @color-text;
        }
    }
    &.active {
        .like-icon {
            opacity: 1;
            background-image: url(../../assets/img/icons/icon-favorite-active.svg);
        }
    }
}
.like-icon {
    opacity: 0.4;
    display: inline-block;
    vertical-align: middle;
    width: 18px;
    height: 16px;
    margin-right: 10px;
    background: url(../../assets/img/icons/icon-favorite.svg) center center
    no-repeat;
    .transition;
    @media @oldy-end {
        margin-right: 5px;
    }
}
.like-title {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    color: #999;
    .transition;
    @media @oldy-end {
        font-size: 15px;
    }
}
</style>
