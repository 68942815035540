<template>
	<div class="modal-content">
		<div class="modal-title-wrap">
			<h3 class="modal-title">
				Регистрация
			</h3>
			<h4 class="modal-subtitle">
				У вас уже есть аккаунт?
			</h4>
			<a
				href="#"
				class="info"
				@click.stop.prevent="modalClose('signIn')"
			>Войти</a>
		</div>
		<form
			class="form"
			novalidate
			@submit.prevent="registration"
		>
			<BaseInput
				v-model="modalReg.last_name"
				label="Фамилия"
				:style="'height: 42px;'"
				:type="'text'"
				is-capitalize
			>
				<span
					v-if="$v.modalReg.last_name.$error"
					class="help-block form-error"
				>
					{{
						!$v.modalReg.last_name.required
							? "Это обязательное поле"
							: $v.modalReg.last_name.alphaSpace
								? "Только буквы, пробел и дефис"
								: "Введите корректную фамилию"
					}}
				</span>
			</BaseInput>
			<BaseInput
				v-model="modalReg.first_name"
				label="Имя"
				:style="'height: 42px;'"
				:type="'text'"
				is-capitalize
			>
				<span
					v-if="$v.modalReg.first_name.$error"
					class="help-block form-error"
				>
					{{
						!$v.modalReg.first_name.required
							? "Это обязательное поле"
							: $v.modalReg.first_name.alphaSpace
								? "Только буквы, пробел и дефис"
								: "Введите корректное имя"
					}}
				</span>
			</BaseInput>
			<BaseInput
				v-model="modalReg.middle_name"
				label="Отчество"
				:style="'height: 42px;'"
				:type="'text'"
				is-capitalize
			/>
			<div class="field-wrap">
				<label class="field-label">
					<c-select
						:name="'Регион:'"
						autosearch-region
						:default-data="modalReg.region"
						:is-error="$v.modalReg.region.$dirty && !$v.modalReg.region.required"
						@selected="selectRegion"
					/>
				</label>
			</div>
			<BaseInput
				v-model="modalReg.phone"
				:mask="'+7(###)###-##-##'"
				label="Телефон"
				:style="'height: 42px;'"
			>
				<span
					v-if="$v.modalReg.phone.$error"
					class="help-block form-error"
				>
					{{
						!$v.modalReg.phone.required
							? "Это обязательное поле"
							: !$v.modalReg.phone.Phone
								? "Введите корректный номер телефона"
								: "Ошибка поля"
					}}
				</span>
			</BaseInput>
			<BaseInput
				v-model="modalReg.email"
				label="Email"
				:style="'height: 42px;'"
				:type="'email'"
				@blurInput="checkEmail"
			>
				<span
					v-if="$v.modalReg.email.$error"
					class="help-block form-error"
				>
					{{
						!$v.modalReg.email.required
							? "Это обязательное поле"
							: !$v.modalReg.email.email
								? "Введите корректный e-mail"
								: !$v.modalReg.email.maxLength
									? "Недопустимое количество символов"
									: "Ошибка поля"
					}}
				</span>
				<span
					v-if="emailExists"
					class="help-block form-error"
				>
					Пользователь с таким email уже существует
				</span>
			</BaseInput>
			<div class="field-wrap">
				<c-select
					:options="roleOptions"
					:name="'Профиль'"
					:default-data="
						modalReg.role
							? roles.find(x => x.slug === modalReg.role).value
							: ''
					"
					:is-error="$v.modalReg.role.$error"
					@selected="selectRole"
				/>
			</div>
			<BaseInput
				v-model.trim="modalReg.pass"
				label="Пароль"
				:style="'height: 42px;'"
				:type="'password'"
			>
				<span
					v-if="$v.modalReg.pass.$error"
					class="help-block form-error"
				>
					{{
						!$v.modalReg.pass.required
							? "Это обязательное поле"
							: !$v.modalReg.pass.password
								? `Пароль должен состоять из букв (минимум одна заглавная буква), символов и цифр, не менее 8 символов`
								: "Ошибка поля"
					}}
				</span>
			</BaseInput>
			<BaseInput
				v-model.trim="modalReg.confPass"
				label="Повторите пароль"
				:style="'height: 42px;'"
				:type="'password'"
			>
				<span
					v-if="$v.modalReg.confPass.$error"
					class="help-block form-error"
				>
					{{
						!$v.modalReg.confPass.required
							? "Это обязательное поле"
							: !$v.modalReg.confPass.sameAsPassword
								? "Пароли не совпадают"
								: "Ошибка поля"
					}}
				</span>
			</BaseInput>
			<div
				v-if="!isPromoCode"
				class="signup-code"
			>
				<span
					class="signup-code__link"
					@click="isPromoCode = !isPromoCode"
				>У вас есть промокод?</span>
			</div>
			<BaseInput
				v-else
				v-model="modalReg.referral_code"
				label="Введите промокод"
				:style="'height: 42px;'"
				:type="'text'"
				@blurInput="checkPromoCode"
			>
				<span
					v-if="codeExists"
					class="help-block form-error"
				>
					Такого промокода не существует
				</span>
			</BaseInput>
			<div
				class="field-wrap"
				style="height:78px"
			>
				<Recaptcha @validate="validate" />
			</div>
			<div class="field-wrap">
				<BaseErrorMessageForm
					v-if="responseError.isShow"
					:text="responseError.text"
				/>
				<BaseButton
					id="btnRegistration_4"
					class="btnRegistration"
					:is-preloader="isSended"
					orange
					lg
					max-width
				>
					Зарегистрироваться
				</BaseButton>
			</div>
			<div class="field-wrap sm">
				<BaseCheckbox
					v-model="modalReg.isAccept"
					label="Соглашаюсь с условиями обработки"
					link="/privacy-policy"
					text-link="персональных данных"
					:is-error="$v.modalReg.isAccept.$error"
				/>
			</div>
			<div class="field-wrap sm">
				<BaseCheckbox
					v-model="modalReg.isAgree"
					label="Принимаю условия"
					link="/agreement"
					text-link="пользовательского соглашения"
					:is-error="$v.modalReg.isAgree.$error"
				/>
			</div>
		</form>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { required, email, sameAs, maxLength } from 'vuelidate/lib/validators';
import { alphaSpace, Phone, password } from '@/assets/scripts/custom_validators';
import BaseInput from '@/components/BaseComponents/BaseInput.vue';
import BaseButton from '@/components/BaseComponents/BaseButton.vue';
import BaseCheckbox from '@/components/BaseComponents/BaseCheckbox';
import BaseErrorMessageForm from '@/components/BaseComponents/BaseErrorMessageForm.vue';
import User from '@/api/user';
import Recaptcha from '@/components/Recaptcha';

export default {
    components: {
        BaseInput,
        BaseButton,
        BaseCheckbox,
        Recaptcha,
				BaseErrorMessageForm,
    },
    data: () => ({
        emailExists: false,
        codeExists: false,
        isSended: false,
        isPromoCode: false,
        recaptchaToken: false,
				responseError: {
					isShow: false,
					text: 'Ошибка регистрации',
				},
        modalReg: {
            last_name: '',
            first_name: '',
            middle_name: '',
            email: '',
            phone: '+7(',
            region: '',
            study: {
                education: ''
            },
            external_service: {
                slug: 'drugoye-delo'
            },
            pass: '',
            confPass: '',
            isAccept: false,
            isAgree: false,
            role: '',
            referral_code: ''
        }
    }),
    validations: {
        modalReg: {
            last_name: { required, alphaSpace },
            first_name: { required, alphaSpace },
            email: { required, email, maxLength: maxLength(254) },
            phone: { required, Phone },
            region: { required },
            role: { required },
            pass: { required, password },
            confPass: { required, sameAsPassword: sameAs(function() {return this.modalReg.pass;}) },
            isAccept: { sameAs: sameAs(() => true) },
            isAgree: { sameAs: sameAs(() => true) }
        }
    },
    computed: {
        ...mapGetters('cnst', {
            eduOptions: 'getEducation',
            roles: 'getRoles'
        }),

        payload() {
            return this.$store.state.modal.payload;
        },

        roleOptions() {
            return this.roles.map(x => x.value);
        }
    },
    created() {
        if (this.payload && this.payload.email) {
            this.modalReg.email = this.payload.email;
        }
        if (this.payload && this.payload.role) {
            this.modalReg.role = this.payload.role;
        }
        if (this.payload && this.payload.external_service) {
            this.modalReg.external_service = {name: this.payload.external_service}
        }
    },
    methods: {
        ...mapActions('user', ['regin', 'detail', 'loginExternal', 'loginEvent']),
        ...mapMutations('user', ['setTransitionAfterReg']),
        validate (success) {
            this.recaptchaToken = success
        },
        selectRole(value) {
            this.modalReg.role = this.roles.find(
                rol => rol.value === value
            ).slug;
        },
        registration() {
            this.$v.modalReg.$touch();
            if (this.$v.modalReg.$invalid) {
                console.log('Error in modal Reg');
            } else if ((!this.isSended || !this.$v.modalReg.$invalid) && !this.codeExists && this.recaptchaToken) {
                this.isSended = true;
								this.responseError.isShow = false;
                localStorage.removeItem('userRole')
                const sendData = {
                    last_name: this.modalReg.last_name,
                    first_name: this.modalReg.first_name,
                    middle_name: this.modalReg.middle_name,
                    phone: this.modalReg.phone,
                    region: this.modalReg.region,
                    email: this.modalReg.email,
                    user_role: this.modalReg.role,
                    password: this.modalReg.pass,
                    referrer_page: window.location.pathname,
                    recaptchaToken: this.recaptchaToken
                };
                if (this.modalReg.referral_code)
                    sendData.referral_code = this.modalReg.referral_code;
                if (this.$route.query.modal === 'reg-form-drugoye-delo')
                    sendData.external_service = this.modalReg.external_service;
                if (this.modalReg.external_service && this.modalReg.external_service.name) {
                    sendData.external_service = {name: this.modalReg.external_service.name}
                    if (this.modalReg.external_service.name === 'Технопарк') {
                        sendData.external_service.slug = 'technopark';
                    }
                }
                this.sendAnalytics()
                this.regin({
                    data: { ...sendData },
                    path: '/onboarding'
                })
                    .then(content => {
                        localStorage.removeItem('nameComponentOnboarding')
                        localStorage.removeItem('currentAim')
                        localStorage.removeItem('onboardingAnswers')
                        localStorage.removeItem('isOnboardingFinished')
                        if (!IS_DEV) {
                            this.$gtm?.push({
                                'event': 'registration',
                                'userID': content.id,
                                'roleUser': content.user_role
                            })
                        }
                        setTimeout(() => {
                            if (this.payload === 'rolesLandings' || this.payload === 'test'  || this.payload === 'eventConstructor') {
                                this.loginEvent({ data: {...sendData}, slug: this.$route.params.slug })
                            }
                            if (!IS_DEV) {
                                if (content.auth_current_page && this.$isMobile()) {
                                    localStorage.setItem('isFromMobile', content.auth_current_page)
                                    window.location.href = content.auth_current_page
                                }
                                window.location.href = content.path;
                            }
                            if (localStorage.getItem('oauthUrl')) {
                                window.location.href = localStorage.getItem('oauthUrl');
                                localStorage.removeItem('oauthUrl');
                            }
                            if (content.user_role !== 'Teacher' && !content.auth_current_page && this.payload !== 'eventConstructor') {
															this.$router.push('/onboarding');
														}
                            if (this.$route.params.slug === 'ocean') window.location.href = 'https://okean.moyastrana.ru/'
                            if (this.$route.query.redirect_url !== null && (this.$route.fullPath.includes('/external_oauth'))) {
                                localStorage.setItem('redirectURL', this.$route.query.redirect_url);
                                window.location.href = 'https://liftbudushhego.moyastrana.ru'
                            }
                            this.modalOpen('regSuccess');
                        }, IS_DEV ? 0 : 2000)
                    })
                    .catch(error => {											
                        this.isSended = false;
												this.responseError.isShow = true;
												for (let key in error) {							
													this.responseError.text = error[key][0];
												}
                    })
            }
        },
        selectEdu(val) {
            this.modalReg.study.education = val;
        },
        selectRegion(val) {
            this.modalReg.region = val;
        },
        async checkEmail() {
            try {
                if (this.modalReg.email) {
                    await User.checkEmail(this.modalReg.email)
                    this.emailExists = true
                }
            } catch {
                this.emailExists = false
            }
        },
        async checkPromoCode() {
            this.codeExists = false
            try {
                if (this.modalReg.referral_code) {
                    await User.checkPromoCode(this.modalReg.referral_code)
                    this.codeExists = false
                }
            } catch {
                this.codeExists = true
            }
        },
        sendAnalytics() {
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath !== '/school' && this.$route.fullPath !== '/student') {
                this.$metrika.reachGoal('form_registration')
            }
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath === '/school') {
                this.$metrika.reachGoal('schoolreg')
            }
            if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru') && this.$route.fullPath === '/school') {
                this.$metrika.reachGoal('studentreg')
            }
        }
    }
};
</script>
<style lang="less" scoped>
.social-button {
    margin: 12px 5px 0 0;
    display: inline-block;
    line-height: 42px;
    padding: 0 22px;
}
.signup-social-buttons {
    padding-top: 30px;
}
.info {
    color: #999;
}
</style>
