<template>
	<div class="modal-content">
		<div class="modal-notice__block">
			<img
				:src="'/img/icons/icon-modal-success.png' | prodSrcImage"
				alt=""
				class="modal-notice__icon"
			>
			<h4 class="modal-notice__title">
				{{ payload }}
			</h4>
			<div
				class="button orange lg"
				@click.stop.prevent="close()"
			>
				Закрыть
			</div>
		</div>
	</div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
    computed: {
        ...mapGetters('modal', {
            payload: 'getPayload',
        }),
    },
    methods: {
        close () {
            window.location.reload()
        }
    }
}
</script>
