<template>
	<div class="vacancy-quick-response-block">
		<form id="formAuth">
			<div class="cells">
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model="$v.stepForm.email.$model"
								type="text"
								class="field"
								placeholder="Email"
							>
							<div class="label">Email:</div>
							<span
								v-if="$v.stepForm.email.$error"
								class="help-block form-error"
							>
								{{
									!$v.stepForm.email.required ? 'Это обязательное поле' : !$v.stepForm.email.email ? 'Введите корректный e-mail' : 'Ошибка поля'
								}}
							</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model="$v.stepForm.pass.$model"
								type="password"
								class="field"
								placeholder="Пароль"
							>
							<div class="label">Пароль:</div>
							<span
								v-if="$v.stepForm.pass.$error"
								class="help-block form-error"
							>
								{{
									!$v.stepForm.pass.required ? 'Это обязательное поле' : !$v.stepForm.pass.minLength ? `Пароль должен состоять из букв и цифр, не менее ${$v.modalAuth.pass.$params.minLength.min} символов` : 'Ошибка поля'
								}}
							</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<a
								href="#"
								class="info"
								@click.stop.prevent="modalClose('recPass')"
							>Забыли пароль?</a>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap md">
						<button
							v-if="!isLoading"
							id="btnFastSignInInternship"
							class="button orange full"
							@click.prevent="signIn"
						>
							Авторизоваться
						</button>
						<div
							v-else
							class="button orange button-preloader"
						>
							Авторизоваться
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap md">
						<button
							class="button-hollow full"
							@click.prevent="nextStep"
						>
							У меня нет аккаунта
						</button>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import {mapActions} from 'vuex';
import {required, email, minLength} from 'vuelidate/lib/validators';

export default {
    data: () => ({
        isLoading: false,
        stepForm: {
            email: '',
            pass: ''
        }
    }),
    validations: {
        stepForm: {
            email: {
                required,
                email
            },
            pass: {
                required,
                minLength: minLength(8)
            }
        }
    },
    created() {
        if (IS_DEV) {
            this.stepForm.email = 'vishnyakov.d.a@mail.ru'
            this.stepForm.pass = 'Lift1234'
        }
    },
    methods: {
        ...mapActions('user', [
            'login',
            'detail',
            'getAllResponses'
        ]),
        ...mapActions('tb', [
            'getAllCourses',
            'getAllSections'
        ]),
        signIn() {
            this.$v.stepForm.$touch()
            if (this.$v.$invalid) {
                this.$scrollTo(document.querySelector('#formAuth'))
            } else {
                this.isLoading = true
                if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                    this.$metrika.reachGoal('fast_response_enter', {
                        URL: document.location.href
                    })
                }
                this.login({email: this.stepForm.email.toLowerCase(), pass: this.stepForm.pass})
                    .then(() => {
                        Promise.all([
                            this.detail(),
                            this.getAllCourses(),
                            this.getAllSections()
                        ]).finally(() => this.isLoading = false)
                    })
                    .then(() => this.getAllResponses())
                    .then(result => {
                        if (this.checkLimitResponses(result)) {
                            this.nextStep()
                        } else {
                            this.modalOpen('stdFail', 'Мы уже получили от тебя отклик на эту вакансию')
                        }
                    })
                    .catch(error => this.modalOpen('fastSignInFail', error))
            }
        },
        nextStep() {
            this.$emit('next-step')
        },
        checkLimitResponses(responses) {
            const vacancy = this.$store.getters['modal/getPayload']
            // FIXME: заменить на значение из глобальных настроек сайта
            const limit = 1
            const totalResponse = responses.reduce((accum, current) => {
                if (current.vacancy.id === vacancy.id) return accum += 1
                else return accum
            }, 0)

            return totalResponse < limit
        }
    }
}
</script>
