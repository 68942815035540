<template>
	<div class="modal-content">
		<div class="contest-task">
			<div class="modal-title sm">
				Расскажите о вашем образовании
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Тип образования:
				</h4>
				<div class="field-questionary">
					<select-2
						:options="EDU"
						:value="SP.name.length > 29 ? SP.name.slice(0, 29) + '...' : SP.name"
						:error="$v.SP.name.$dirty && !$v.SP.name.required ? 'Это обязательное поле' : ''"
						@selected="selectSPEdu"
					/>
				</div>
			</div>
			<div
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Учебное заведение:
				</h4>
				<div class="field-questionary">
					<c-select
						autosearch-education
						is-contest-task
						is-onboarding
						:default-data="SP.institution.name"
						:is-error="$v.SP.institution.name.$dirty && !$v.SP.institution.name.required"
						@selected="selectSPEduFac"
					/>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Специализация:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.SP.specialization.$error}"
				>
					<input
						v-model="SP.specialization"
						type="text"
						class="field-simple__value"
					>
					<div
						v-if="$v.SP.specialization.$error"
						class="field-simple__error"
					>
						Это обязательное поле
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Город:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.SP.city.$error}"
				>
					<input
						v-model.trim="SP.city"
						type="text"
						class="field-simple__value"
					>
					<div
						v-if="$v.SP.city.$error"
						class="field-simple__error"
					>
						Это обязательное поле
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Год окончания:
				</h4>
				<div class="field-questionary">
					<select-2
						:options="options.years"
						:value="SP.graduation_year"
						:error="$v.SP.graduation_year.$dirty && $v.SP.graduation_year.$error ? 'Это обязательное поле' : ''"
						@selected="selectSPYear"
					/>
				</div>
			</div>
			<div class="modal-title sm">
				Расскажите о вашем опыте работы
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Год начала работы:
				</h4>
				<div class="field-questionary">
					<select-2
						:options="availableYearStart"
						:value="valueYearStart"
						:error="$v.WP.commencement_date.$dirty && !($v.WP.commencement_date.required && $v.WP.commencement_date.WPYear) ? 'Это обязательное поле' : ''"
						@selected="selectYear($event, 'commencement_date')"
					/>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Месяц начала работы:
				</h4>
				<div class="field-questionary">
					<select-2
						:options="availableMonthsStart"
						:value="valueMonthStart"
						:error="$v.WP.commencement_date.$dirty && !($v.WP.commencement_date.required && $v.WP.commencement_date.WPMonth) ? 'Это обязательное поле' : ''"
						@selected="selectMonth($event, 'commencement_date')"
					/>
				</div>
			</div>
			<template v-if="!WP.to_present">
				<div class="contest-task__field">
					<h4 class="contest-task__subtitle">
						Год окончания работы:
					</h4>
					<div class="field-questionary">
						<select-2
							:options="availableYearEnd"
							:value="valueYearEnd"
							:error="$v.WP.completion_date.$dirty && !($v.WP.completion_date.required && $v.WP.completion_date.WPYear) ? 'Это обязательное поле' : ''"
							@selected="selectYear($event, 'completion_date')"
						/>
					</div>
				</div>
				<div class="contest-task__field">
					<h4 class="contest-task__subtitle">
						Месяц окончания работы:
					</h4>
					<div class="field-questionary">
						<select-2
							:options="availableMonthsEnd"
							:value="valueMonthEnd"
							:error="$v.WP.completion_date.$dirty && !($v.WP.completion_date.required && $v.WP.completion_date.WPMonth) ? 'Это обязательное поле' : ''"
							@selected="selectMonth($event, 'completion_date')"
						/>
					</div>
				</div>
			</template>
			<div
				class="contest-task__field"
			>
				<div class="field-simple">
					<label class="checkbox-filter">
						<input
							v-model="WP.to_present"
							type="checkbox"
							@change="changePresent"
						>
						<span>Продолжаю работать</span>
					</label>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Организация:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.WP.organization.$error}"
				>
					<input
						v-model.trim="WP.organization"
						type="text"
						class="field-simple__value"
					>
					<div
						v-if="$v.WP.organization.$error"
						class="field-simple__error"
					>
						Это обязательное поле
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Должность:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.WP.position.$error}"
				>
					<input
						v-model.trim="WP.position"
						type="text"
						class="field-simple__value"
					>
					<div
						v-if="$v.WP.position.$error"
						class="field-simple__error"
					>
						Это обязательное поле
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Город:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.WP.city.$error}"
				>
					<input
						v-model.trim="WP.city"
						type="text"
						class="field-simple__value"
					>
					<div
						v-if="$v.WP.city.$error"
						class="field-simple__error"
					>
						Это обязательное поле
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Обязанности на рабочем месте:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.WP.duties_at_work.$error}"
				>
					<input
						v-model="WP.duties_at_work"
						type="text"
						class="field-simple__value"
					>
					<div
						v-if="$v.WP.duties_at_work.$error"
						class="field-simple__error"
					>
						{{ !$v.WP.duties_at_work.required ? 'Это обязательное поле' :
							!$v.WP.duties_at_work.minLength && `Не менее ${$v.WP.duties_at_work.$params.minLength.min} символов` }}
					</div>
				</div>
			</div>
			<div
				class="button contest-task__button"
				@click="sendRequest"
			>
				Отправить
			</div>
		</div>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { minLength, minValue, required } from 'vuelidate/lib/validators';
import { WPMonth, WPYear } from '@/assets/scripts/custom_validators';
import Cnt from '@/api/contests';

export default {
    filters: {
        dateForViewFormat(val) {
            if (val) {
                let [year, month, day] = val.includes('.') ? val.split('.').reverse() : val.split('-')

                if (day) {
                    return `${this.months[parseInt(month) - 1]} ${year} г.`
                } else if (month) {
                    return `${this.months[month]} ${year} г.`
                } else {
                    return val
                }
            } else {
                return ''
            }
        },
        WPDateForInnerFormat(val, slug = null) {
            if (slug === 'Y') {
                return parseInt(val.split('-')[0])
            } else if (slug === 'M') {
                return parseInt(val.split('-')[1]) - 1
            } else {
                return `${parseInt(val.split('-')[1]) - 1}.${parseInt(val.split('-')[0])}`
            }
        }
    },
    data: () => ({
        SP: {
            name: '',
            institution: {
                name: ''
            },
            specialization: '',
            school_class_number: null,
            number_course: null,
            school_number: null,
            city: '',
            graduation_year: 0,
            primary_education: false
        },
        WP: {
            commencement_date: '',
            completion_date: '',
            to_present: false,
            city: '',
            organization: '',
            position: '',
            duties_at_work: ''
        },
        options: {
            years: [],
            months: []
        }
    }),
    validations() {
        const SP = {
            name: { required },
            institution: {
                name: {required}
            },
            graduation_year: {
                minValue: minValue(1980),
                required
            },
            specialization: { required },
            city: { required },
        }

        const WP = {
            commencement_date: {
                required,
                WPYear,
                WPMonth
            },
            organization: {
                required
            },
            city: {
                required
            },
            position: {
                required
            },
            duties_at_work: {
                required,
                minLength: minLength(10)
            }
        }

        if (!this.WP.to_present) {
            WP.completion_date = {
                required,
                WPYear,
                WPMonth
            }
        }

        return {
            SP, WP
        }
    },
    computed: {
        ...mapGetters('cnst', {
            EDU: 'getEducation',
            months: 'getMonths'
        }),
        userInfo() {
            return this.$store.state.modal.payload.expertInfo;
        },
        contestID() {
            return this.$store.state.modal.payload.contestID;
        },
        nomination_ids() {
            return this.$store.state.modal.payload.nomination_ids;
        },
        availableYearStart() {
            if (this.WP.to_present) {
                return this.options.years
            } else {
                let yearEnd = this.WP.completion_date.split('.')[1]

                if (yearEnd) return this.options.years.slice(0, this.options.years.findIndex(x => x == yearEnd) + 1)
                else return this.options.years
            }
        },
        availableYearEnd() {
            let yearStart = this.WP.commencement_date.split('.')[1]
            if (yearStart) return this.options.years.slice(this.options.years.findIndex(x => x == yearStart))
            else return this.options.years
        },
        availableMonthsStart() {
            if (this.WP.to_present) {
                return this.months
            } else {
                let yearStart = this.WP.commencement_date.split('.')[1],
                    yearEnd = this.WP.completion_date.split('.')[1],
                    monthEnd = this.WP.completion_date.split('.')[0];

                if (yearStart) {
                    if (+yearStart < new Date().getFullYear()) {
                        if (yearStart < yearEnd) return this.months
                        // yearStart == yearEnd
                        else {
                            if (monthEnd) return this.months.slice(0, parseInt(monthEnd) + 1)
                            else return this.months
                        }
                    }
                    else return this.months.slice(0, new Date().getMonth() + 1)
                } else {
                    return this.months
                }
            }
        },
        availableMonthsEnd() {
            let yearEnd = this.WP.completion_date.split('.')[1],
                yearStart = this.WP.commencement_date.split('.')[1],
                monthStart = this.WP.commencement_date.split('.')[0];
            if (yearEnd) {
                if (+yearEnd < new Date().getFullYear()) {
                    if (yearStart < yearEnd) return this.months
                    // yearStart == yearEnd
                    else {
                        if (monthStart) return this.months.slice(parseInt(monthStart))
                        else return this.months
                    }
                }
                else return this.months.slice(0, new Date().getMonth() + 1)
            } else if (monthStart) {
                if (yearStart === yearEnd || yearEnd === undefined || yearEnd === '') {
                    return this.months.slice(parseInt(monthStart))
                } else return this.months
                return this.months
            } else {
                return this.months
            }
        },
        valueYearStart() {
            let result = this.WP.commencement_date.split('.')[1]

            if (result) return +result
            else return ''
        },
        valueYearEnd() {
            let result = this.WP.completion_date.split('.')[1]
            if (result) return +result
            else return ''
        },
        valueMonthStart() {
            return this.options.months[this.WP.commencement_date.split('.')[0]]
        },
        valueMonthEnd() {
            return this.options.months[this.WP.completion_date.split('.')[0]]
        }
    },
    created() {
        this.options.months = this.months
        let start = 1980
        while (start <= new Date().getFullYear() + 6) {
            this.options.years.push(start++)
        }

        this.getStudyPlace().then(res => {
            res.forEach((study) => {
                if (study.primary_education) this.SP = study
            })
        })
        this.getWorkPlace().then(res => {
            let workPlaces = []
            workPlaces = res && res.length && res.map(x => ({
                ...x,
                commencement_date: this.$options.filters.WPDateForInnerFormat(x.commencement_date),
                completion_date: x.to_present ? null : this.$options.filters.WPDateForInnerFormat(x.completion_date)
            })) || []
            if (workPlaces.length > 0) this.WP = workPlaces[0]
        })
    },
    methods: {
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlace',
            getWorkPlace: 'getWorkPlace'
        }),
        selectYear(val, slug) {
            if (this.WP[slug]) {
                this.WP[slug] = `${this.WP[slug].split('.')[0]}.${val}`
            } else {
                this.WP[slug] = `.${val}`
            }
        },
        selectMonth(val, slug) {
            if (this.WP[slug]) {
                this.WP[slug] = `${this.months.findIndex(x => x === val)}.${this.WP[slug].split('.')[1]}`
            } else {
                this.WP[slug] = `${this.months.findIndex(x => x === val)}.`
            }
        },
        changePresent(event) {
            if (event.target.checked) this.WP.to_present = true
            else {
                this.WP.to_present = false
                this.WP.completion_date = ''
            }
        },
        selectCity(val) {
            this.WP.city = val
        },
        selectSPEdu(val) {
            this.SP.name = val
        },
        selectSPEduFac(val) {
            this.SP.institution.name = val.toUpperCase();
        },
        selectSPYear(val) {
            this.SP.graduation_year = val
        },
        sendRequest() {
            this.$v.SP.$touch();
            this.$v.WP.$touch();
            if (this.$v.SP.$invalid || this.$v.WP.$invalid) {
                console.log('error');
            } else {
                const data = {
                    nomination_ids: this.nomination_ids,
                    user_name: this.userInfo.user_name,
                    user_last_name: this.userInfo.user_last_name,
                    user_middle_name: this.userInfo.user_middle_name,
                    user_birthday: this.userInfo.user_birthday,
                    user_phone: this.userInfo.user_phone,
                    user_city: this.userInfo.user_city,
                    user_region: this.userInfo.user_region,
                    user_photo: this.userInfo.user_photo,
                    user_email: this.userInfo.user_email,
                    user_data: {
                        study_place: this.SP,
                        work_place: this.WP
                    }
                }
                Cnt.sendExpertRequest(this.contestID, data)
                    .then(() => this.modalOpen('contest-ok'))
                    .catch(() => this.modalOpen('stdFail', 'Ваша заявка, чтобы стать экспертом подана'))
            }
        }
    }
}
</script>
