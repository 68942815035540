<template>
	<div class="modal-content">
		<div
			v-if="expertInfo"
			class="contest-task"
		>
			<div class="modal-title sm">
				Расскажите о себе
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Имя:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.expertInfo.user_name.$error}"
				>
					<input
						v-model.trim="$v.expertInfo.user_name.$model"
						type="text"
						class="field-simple__value"
						placeholder="Введите ваше имя"
					>
					<div
						v-if="$v.expertInfo.user_name.$error"
						class="field-simple__error"
					>
						{{
							!$v.expertInfo.user_name.required ? 'Это обязательное поле' :
							!$v.expertInfo.user_name.maxLength ? `Не более 30 символов` : 'Ошибка поля' ||
								!$v.expertInfo.user_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное имя'
						}}
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Фамилия:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.expertInfo.user_last_name.$error}"
				>
					<input
						v-model.trim="$v.expertInfo.user_last_name.$model"
						type="text"
						class="field-simple__value"
						placeholder="Введите вашу фамилию"
					>
					<div
						v-if="$v.expertInfo.user_last_name.$error"
						class="field-simple__error"
					>
						{{
							!$v.expertInfo.user_last_name.required ? 'Это обязательное поле' :
							!$v.expertInfo.user_last_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректную фамилию'
						}}
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Отчество:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.expertInfo.user_middle_name.$error}"
				>
					<input
						v-model.trim="$v.expertInfo.user_middle_name.$model"
						type="text"
						class="field-simple__value"
						placeholder="Введите ваше отчество"
					>
					<div
						v-if="$v.expertInfo.user_middle_name.$error"
						class="field-simple__error"
					>
						{{
							!$v.expertInfo.user_middle_name.required ? 'Это обязательное поле' :
							!$v.expertInfo.user_middle_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное отчество'
						}}
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Дата рождения:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.expertInfo.user_birthday.$error}"
				>
					<input
						v-model.trim="expertInfo.user_birthday"
						v-mask="'##.##.####'"
						type="text"
						class="field-simple__value"
						placeholder="Введите вашу дату рождения"
					>
					<div
						v-if="$v.expertInfo.user_birthday.$error"
						class="field-simple__error"
					>
						{{
							!$v.expertInfo.user_birthday.required ? 'Это обязательное поле' : !$v.expertInfo.user_birthday.Birthday ? 'Формат даты ДД.ММ.ГГГГ' : 'Ошибка поля'
						}}
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Телефон:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.expertInfo.user_phone.$error}"
				>
					<input
						v-model="expertInfo.user_phone"
						v-mask="'+7(###)###-##-##'"
						type="text"
						class="field-simple__value"
						placeholder="Введите ваш телефон"
					>
					<div
						v-if="$v.expertInfo.user_phone.$error"
						class="field-simple__error"
					>
						{{
							!$v.expertInfo.user_phone.required ? 'Это обязательное поле' : !$v.expertInfo.user_phone.Phone ? 'Введите корректный номер телефона' : 'Ошибка поля'
						}}
					</div>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					E-mail:
				</h4>
				<div
					class="field-simple"
					:class="{'field-simple--error': $v.expertInfo.user_email.$error}"
				>
					<input
						v-model.trim="expertInfo.user_email"
						type="email"
						disabled
						class="field-simple__value"
						placeholder="Введите ваш e-mail"
					>
					<div
						v-if="$v.expertInfo.user_email.$error"
						class="field-simple__error"
					>
						{{
							!$v.expertInfo.user_email.required ? 'Это обязательное поле' :
							!$v.expertInfo.user_email.email ? 'Введите корректный e-mail (на английском)' :
							!$v.expertInfo.user_email.maxLength ? `Не более ${$v.expertInfo.user_email.maxLength.max} символов` : 'Ошибка поля'
						}}
					</div>
				</div>
			</div>
			<div
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Город:
				</h4>
				<div class="field-questionary">
					<c-select
						:key="chosenCity"
						autosearch-city
						:region-name="expertInfo.user_region"
						:default-data="expertInfo.user_city"
						:is-error="$v.expertInfo.user_city.$dirty && !$v.expertInfo.user_city.required"
						is-contest-task
						is-onboarding
						@selected="selectCity"
						@defaultRegion="chooseRegion"
					/>
				</div>
			</div>
			<div
				class="contest-task__field"
			>
				<h4 class="contest-task__subtitle">
					Регион:
				</h4>
				<div class="field-questionary">
					<c-select
						:key="chosenRegion"
						autosearch-region
						:default-data="expertInfo.user_region"
						:is-error="$v.expertInfo.user_region.$dirty && !$v.expertInfo.user_region.required"
						is-contest-task
						is-onboarding
						@selected="selectRegion"
						@defaultCity="chooseCity"
					/>
				</div>
			</div>
			<div class="contest-task__field">
				<h4 class="contest-task__subtitle">
					Фотография:
				</h4>
				<UploadFile
					:key="componentKey"
					file-name="Фотография"
					accept-files="jpg, jpeg, png"
					required
					:is-loading="isLoading"
					@uploaded="uploadPhoto"
				/>
			</div>
			<div
				class="button contest-task__button"
				@click="sendUserInfo"
			>
				Отправить
			</div>
		</div>
	</div>
</template>

<script>
import { email, maxLength, required } from 'vuelidate/lib/validators';
import { alphaSpace, Birthday, Phone } from '@/assets/scripts/custom_validators';
import { mapActions, mapGetters } from 'vuex';
import UploadFile from '@/components/contest/UploadFile.vue';
import Cnt from '@/api/contests';

export default {
    components: { UploadFile },
    data: () => ({
        expertInfo: {
            user_name: '',
            user_last_name: '',
            user_middle_name: '',
            user_phone: '',
            user_city: '',
            user_email: '',
            user_birthday: '',
            user_region: '',
            user_photo: ''
        },
        chosenRegion: 0,
        chosenCity: 0,
        componentKey: 0,
        isCityError: false,
        isCityClear: false,
        isLoading: false,
    }),
    validations() {
        return {
            expertInfo: {
                user_name: {
                    required,
                    maxLength: maxLength(30),
                    alphaSpace
                },
                user_last_name: {
                    required,
                    alphaSpace
                },
                user_middle_name: {
                    alphaSpace,
                    required
                },
                user_phone: {
                    required,
                    Phone
                },
                user_city: {
                    required,
                    alphaSpace
                },
                user_region: {
                    required,
                    alphaSpace
                },
                user_email: {
                    email,
                    required,
                    maxLength: maxLength(320)
                },
                user_birthday: {
                    required,
                    Birthday
                }
            }
        }
    },
    computed: {
        contestID() {
            return this.$store.state.modal.payload;
        },
        ...mapGetters('user', {
            isLogged: 'isLoggedIn',
            user: 'userInfo'
        }),
    },
    created() {
        this.getUserData()
    },
    methods: {
        ...mapActions('user', {
            getUserInfo: 'detail',
            sendChanges: 'change'
        }),

        getUserData () {
            this.getUserInfo()
                .then(user => {
                    let [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', '']
                    this.expertInfo.user_name = user.first_name
                    this.expertInfo.user_last_name = user.last_name
                    this.expertInfo.user_middle_name = user.middle_name
                    this.expertInfo.user_phone = user.phone
                    this.expertInfo.user_region = user.region
                    this.expertInfo.user_city = user.city ? user.city.name : ''
                    this.expertInfo.user_email = user.email
                    this.expertInfo.user_birthday = `${day}.${month}.${year}`
                })
        },
        selectCity(val) {
            if (val) this.isCityError = false
            if (!val) this.isCityError = true
            this.expertInfo.user_city = val;
        },
        selectRegion(val) {
            this.expertInfo.user_region = val;
        },
        chooseRegion(val) {
            if (!this.expertInfo.user_region) {
                this.expertInfo.user_region = val
                this.chosenRegion++
            }
        },
        chooseCity(val) {
            if ( this.expertInfo.user_city !== '') {
                this.expertInfo.user_city = val
                this.chosenCity++
            }
        },
        uploadPhoto(file) {
            let photo = Object.values(file);
            this.expertInfo.user_photo = photo[0];
        },
        sendUserInfo () {
            this.$v.expertInfo.$touch();
            if (this.$v.expertInfo.$invalid || this.expertInfo.user_photo === '') {
                console.log('error');
            } else {
                this.isLoading = false
                let birthday = this.expertInfo.user_birthday
                birthday = `${this.expertInfo.user_birthday.slice(-4)}-${this.expertInfo.user_birthday.slice(3, -5)}-${this.expertInfo.user_birthday.slice(0, 2)}`
                this.expertInfo.user_birthday = birthday
                this.sendChanges({
                    id: this.user.id,
                    first_name: this.expertInfo.user_name,
                    last_name: this.expertInfo.user_last_name,
                    middle_name: this.expertInfo.user_middle_name,
                    phone: this.expertInfo.user_phone,
                    region: this.expertInfo.user_region,
                    city: {
                        name: this.expertInfo.user_city
                    },
                    birthday: birthday,
                    employment_type: [],
                }).then(() => {
                    this.modalOpen('choose-nominations', {
                        expertInfo: this.expertInfo,
                        contestID: this.contestID
                    })
                })
            }
        },
    }
}
</script>
