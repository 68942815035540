<template>
	<div class="vacancy-quick-response-block">
		<form>
			<div class="cells">
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.stepForm.last_name.$model"
								type="text"
								class="field"
								placeholder="Фамилия"
							>
							<div class="label">Фамилия:</div>
							<span
								v-if="$v.stepForm.last_name.$error"
								class="help-block form-error"
							>
								{{ !$v.stepForm.last_name.required ? 'Это обязательное поле' : !$v.stepForm.last_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Ошибка поля' }}
							</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.stepForm.first_name.$model"
								type="text"
								class="field"
								placeholder="Имя"
							>
							<div class="label">Имя:</div>
							<span
								v-if="$v.stepForm.first_name.$error"
								class="help-block form-error"
							>
								{{ !$v.stepForm.first_name.required ? 'Это обязательное поле' :
									!$v.stepForm.first_name.maxLength ? `Не более 30 символов` : 'Ошибка поля' ||
										$v.stepForm.first_name.alphaSpace ? 'Только буквы, пробел и дефис' : 'Введите корректное имя' }}
							</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.stepForm.middle_name.$model"
								type="text"
								class="field"
								placeholder="Отчество"
							>
							<div class="label">Отчество:</div>
							<span
								v-if="$v.stepForm.middle_name.$error"
								class="help-block form-error"
							>Только буквы, пробел и дефис</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.stepForm.birthday.$model"
								v-mask="'##.##.####'"
								type="text"
								class="field"
								placeholder="Дата рождения"
							>
							<div class="label">Дата рождения</div>
							<span
								v-if="$v.stepForm.birthday.$error"
								class="help-block form-error"
							>
								{{ !$v.stepForm.birthday.required ? 'Это обязательное поле' : !$v.stepForm.birthday.Birthday ? 'Формат даты ДД.ММ.ГГГГ' : 'Ошибка поля' }}
							</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.stepForm.email.$model"
								type="text"
								class="field"
								placeholder="Email"
								@keypress="$emit('reset-errors', 'email')"
								@change="$emit('reset-errors', 'email')"
							>
							<div class="label">Email:</div>
							<span
								v-if="$v.stepForm.email.$error || getError('email')"
								class="help-block form-error"
							>
								{{
									!$v.stepForm.email.required ? 'Это обязательное поле' :
									!$v.stepForm.email.email ? 'Введите корректный e-mail (на английском)' :
									!$v.stepForm.email.maxLength ? `Не более ${$v.stepForm.email.maxLength.max} символов` : getError('email')
								}}
							</span>
						</label>
					</div>
				</div>
				<div
					v-if="!isLogged"
					class="cell"
				>
					<div class="field-wrap">
						<c-select
							:options="roleOptions"
							:name="'Профиль'"
							:default-data="
								stepForm.role
									? roles.find(x => x.slug === stepForm.role).value
									: ''
							"
							:is-error="$v.stepForm.role.$error"
							@selected="selectRole"
						/>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model="stepForm.phone"
								v-mask="'+7(###)###-##-##'"
								type="text"
								class="field"
								placeholder="Телефон"
								@keypress="$emit('reset-errors', 'phone')"
								@change="$emit('reset-errors', 'phone')"
							>
							<div class="label">Телефон:</div>
							<span
								v-if="$v.stepForm.phone.$error || getError('phone')"
								class="help-block form-error"
							>
								{{
									!$v.stepForm.phone.required ? 'Это обязательное поле' : !$v.stepForm.phone.Phone ? 'Введите корректный номер телефона' : getError('phone')
								}}
							</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.stepForm.city.$model"
								type="text"
								class="field"
								placeholder="Город"
							>
							<div class="label">Город</div>
							<span
								v-if="$v.stepForm.city.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap">
						<select-2
							:options="edu"
							:value="stepForm.study.edu"
							:label="'Образование:'"
							:error="$v.stepForm.study.edu.$error ? 'Это обязательное поле' : ''"
							@selected="selectEdu"
						/>
					</div>
				</div>
				<transition-group name="slide">
					<div
						v-if="isExtraStudy"
						key="edu_fac"
						class="cell"
					>
						<div class="field-wrap">
							<label class="field-label">
								<c-select
									autosearch-education
									:name="'Учебное заведение'"
									:is-error="$v.stepForm.study.edu_fac.$error"
									:default-data="stepForm.study.edu_fac"
									@selected="selectEduFac"
								/>
							</label>
						</div>
					</div>
					<div
						key="spec"
						class="cell"
					>
						<div class="field-wrap">
							<label class="field-label">
								<input
									v-model="$v.stepForm.study.spec.$model"
									type="text"
									class="field"
									placeholder="Специализация"
								>
								<div class="label">Специализация</div>
								<span
									v-if="$v.stepForm.study.spec.$error"
									class="help-block form-error"
								>
									{{
										!$v.stepForm.study.spec.maxLength ? `Не более ${$v.stepForm.study.spec.maxLength.max} символов` : 'Ошибка поля' ||
											!$v.stepForm.study.spec.alphaSpace ? 'Введите корректное название специализации' : 'Ошибка поля'
									}}
								</span>
							</label>
						</div>
					</div>
					<div
						key="grad_year"
						class="cell"
					>
						<div class="field-wrap">
							<select-2
								:options="options.years"
								:value="stepForm.study.grad_year"
								:label="'Год окончания:'"
								:error="$v.stepForm.study.grad_year.$error ? 'Это обязательное поле' : ''"
								@selected="selectYear"
							/>
						</div>
					</div>
				</transition-group>
				<div class="cell full" />
				<div
					v-if="!isLogged"
					class="cell"
				>
					<BaseInput
						v-model.trim="$v.stepForm.password.$model"
						label="Пароль"
						:style="'height: 42px;'"
						:type="'password'"
					>
						<span
							v-if="$v.stepForm.password.$error"
							class="help-block form-error"
						>
							{{
								!$v.stepForm.password.required
									? "Это обязательное поле"
									: !$v.stepForm.password.alphaNumber || !$v.stepForm.password.minLength
										? `Пароль должен состоять из букв и цифр, не менее ${$v.stepForm.password.$params.minLength.min} символов`
										: "Ошибка поля"
							}}
						</span>
					</BaseInput>
				</div>
				<div
					v-if="!isLogged"
					class="cell"
				>
					<BaseInput
						v-model.trim="$v.stepForm.confPass.$model"
						label="Повторите пароль"
						:style="'height: 42px;'"
						:type="'password'"
					>
						<span
							v-if="$v.stepForm.confPass.$error"
							class="help-block form-error"
						>
							{{
								!$v.stepForm.confPass.required
									? "Это обязательное поле"
									: !$v.stepForm.confPass.sameAsPassword
										? "Пароли не совпадают"
										: "Ошибка поля"
							}}
						</span>
					</BaseInput>
				</div>
				<div class="cell full" />
				<div
					v-if="!isLogged"
					class="cell"
				>
					<div class="field-wrap md">
						<button
							class="button-hollow"
							@click.prevent="prevStep"
						>
							Назад
						</button>
					</div>
				</div>
				<div class="cell">
					<div class="field-wrap md">
						<button
							v-if="!isSending"
							id="btnFastRegInInternship"
							class="button orange full"
							@click.prevent.stop="nextStep"
						>
							Далее
						</button>
						<div
							v-else
							class="button orange button-preloader"
						>
							Далее
							<div class="button-preloader-icons">
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
								<div class="button-preloader-icon" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</form>
	</div>
</template>

<script>

import {required, email, maxLength, minLength, sameAs} from 'vuelidate/lib/validators'
import {Phone, alphaSpace, alphaEduFac, Birthday, alphaNumber} from '@/assets/scripts/custom_validators'
import {mapActions, mapGetters} from 'vuex';
import BaseInput from '@/components/BaseComponents/BaseInput';
import UserEd from '@/api/userEducation';

export default {
    components: {
        BaseInput
    },
    props: {
        errors: undefined
    },
    data: () => ({
        isSending: false,
        studyPlaces: null,
        stepForm: {
            last_name: '',
            first_name: '',
            middle_name: '',
            role: '',
            birthday: '',
            email: '',
            phone: '',
            city: '',
            study: {
                edu: '',
                spec: '',
                edu_fac: '',
                grad_year: 0
            },
            employment_type: [],
            password: '',
            confPass: ''
        },
        options: {
            years: []
        }
    }),
    computed: {
        ...mapGetters('cnst',{
            edu: 'getEducation',
            roles: 'getRoles'
        }),
        ...mapGetters('user',{
            isLogged: 'isLoggedIn',
            userID: 'userID'
        }),
        isExtraStudy() {
            return !(+this.stepForm.study.edu === 0 || this.stepForm.study.edu.toLowerCase() === 'неоконченное среднее' || this.stepForm.study.edu.toLowerCase() === 'среднее');
        },
        roleOptions() {
            return this.roles.map(x => x.value);
        }
    },
    validations() {
        const base = {
            stepForm: {
                last_name: {
                    required,
                    alphaSpace
                },
                first_name: {
                    required,
                    maxLength: maxLength(30),
                    alphaSpace
                },
                middle_name: { alphaSpace },
                birthday: {
                    required,
                    Birthday
                },
                email: {
                    email,
                    required,
                    maxLength: maxLength(320)
                },
                phone: {
                    required,
                    Phone
                },
                city: {
                    required,
                    alphaSpace
                },
                study: {
                    edu: {required},
                    spec: {
                        maxLength: maxLength(128),
                        alphaSpace
                    },
                    grad_year: {required}
                }
            }
        }
        if (!this.isLogged) {
            base.stepForm.role = { required }
            base.stepForm.password = {
                required,
                minLength: minLength(8),
                alphaNumber
            }
            base.stepForm.confPass = {
                required,
                sameAsPassword: sameAs(function() {
                    return this.stepForm.password;
                })
            }
        }

        if (this.isExtraStudy)
            base.stepForm.study = {
                edu: {required},
                edu_fac: {
                    required,
                    alphaEduFac
                },
                spec: {
                    maxLength: maxLength(128),
                    alphaSpace
                },
                grad_year: {required}
            }

        return base
    },
    created() {
        let start = 1980

        while (start <= new Date().getFullYear() + 6) {
            this.options.years.push(start++)
        }
    },
    mounted() {
        this.init()
    },
    methods: {
        ...mapActions('user', {
            getStudyPlace: 'getStudyPlace',
            getUserInfo: 'detail'
        }),
        init() {
            if (this.isLogged) {
                this.getUserInfo().then(response => {
                    const user = response
                    const [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', ''];
                    this.stepForm.id = user.id
                    this.stepForm.last_name = user.last_name
                    this.stepForm.first_name = user.first_name
                    this.stepForm.middle_name = user.middle_name
                    this.stepForm.birthday = user.birthday && `${day}.${month}.${year}`
                    this.stepForm.email = user.email || ''
                    this.stepForm.phone = user.phone
                    this.stepForm.city = user.city && user.city.name || ''
                    this.stepForm.employment_type = user.employment_type
                    this.getStudyPlace().then(res => {
                        this.studyPlaces = res
                        this.studyPlaces.forEach( item => {
                            if (item.primary_education) {
                                this.stepForm.study.edu = item.name
                                this.stepForm.study.edu_fac = item.institution ? item.institution.name : ''
                                this.stepForm.study.spec = item.specialization ? item.specialization : ''
                                this.stepForm.study.grad_year = item.graduation_year
                            }
                        })
                    })
                })
            }
            const user = this.user || {}
            const [year, month, day] = user.birthday ? user.birthday.split('-') : ['', '', ''];
            this.stepForm.id = user.id
            this.stepForm.last_name = user.last_name
            this.stepForm.first_name = user.first_name
            this.stepForm.middle_name = user.middle_name
            this.stepForm.birthday = user.birthday && `${day}.${month}.${year}`
            this.stepForm.email = user.email || ''
            this.stepForm.phone = user.phone
            this.stepForm.city = user.city
            this.stepForm.employment_type = []
            this.stepForm.study.edu = ''
            this.stepForm.study.edu_fac = ''
            this.stepForm.study.spec =''
            this.stepForm.study.grad_year = ''
        },
        selectCity(val) {
            this.stepForm.city = val
        },
        selectEdu(val) {
            this.stepForm.study.edu = val
            if (!this.isExtraStudy) {
                this.stepForm.study.spec = ''
                this.stepForm.study.edu_fac = ''
            }
            // if (val === 'Высшее' || )
        },
        selectRole(value) {
            this.stepForm.role = this.roles.find(
                rol => rol.value === value
            ).slug
        },
        selectEduFac(val) {
            this.stepForm.study.edu_fac = val.toUpperCase();
        },
        selectYear(val) {
            this.stepForm.study.grad_year = val
        },
        getError(slug) {
            if (this.errors && this.errors.field === slug) {
                return this.errors.text
            } else {
                return false
            }
        },
        regInUser() {
            let [day, month, year] = this.stepForm.birthday && this.stepForm.birthday.split('.')
            return this.$store.dispatch('user/regin', {
                data: {
                    first_name: this.stepForm.first_name,
                    last_name: this.stepForm.last_name,
                    birthday: `${year}-${month}-${day}`,
                    phone: this.stepForm.phone,
                    city: {name: this.stepForm.city},

                    email: this.stepForm.email,
                    study: {
                        education: this.stepForm.study.edu
                    },
                    password: this.stepForm.password,
                    user_role: this.stepForm.role
                }
            })
        },
        sendNewStudyPlace () {
            const data = {
                graduation_year: this.stepForm.study.grad_year ? this.stepForm.study.grad_year : '',
                name: this.stepForm.study.edu ? this.stepForm.study.edu : '',
                primary_education: true,
                specialization: this.stepForm.study.spec ? this.stepForm.study.spec : '',
                city: this.stepForm.city,
                institution: {
                    name:  this.stepForm.study.edu_fac
                },
                school_class_number: null,
                school_number: null
            }
            return UserEd.addStudyPlace({data, id: this.userID})
        },
        createStudyPlace () {
            if (this.studyPlaces !== null && this.studyPlaces.length > 0) {
                let primaryEducation = null
                this.studyPlaces.forEach( item => {
                    if (item.primary_education) primaryEducation = item
                })
                if (primaryEducation !== null) {
                    if (this.stepForm.study.edu === primaryEducation.name &&
                        this.stepForm.study.edu_fac === primaryEducation.institution.name &&
                        this.stepForm.study.spec === primaryEducation.specialization &&
                        this.stepForm.study.grad_year === primaryEducation.graduation_year) {
                        return ''
                    } else {
                        this.sendNewStudyPlace()
                    }
                } else {
                    this.sendNewStudyPlace()
                }
            } else this.sendNewStudyPlace()
        },
        async nextStep() {
            this.$v.stepForm.$touch()
            if (this.$v.$invalid) {
                console.log('Errors in form Step 2', this.$v.stepForm)
            } else if (!this.isSending) {
                if(this.$metrika && (window.location.hostname === 'stage.lift.teachbasetest.ru' || window.location.hostname === 'lift-bf.ru')) {
                    this.$metrika.reachGoal('fast_response_enter_registration', {
                        URL: document.location.href
                    })
                }
                this.isSending = true

                try {
                    this.isLogged ? await this.createStudyPlace() : await this.regInUser().then(() => this.createStudyPlace())
                    this.$emit('next-step', {
                        step: 2,
                        form: {
                            ...this.stepForm,
                            phone: this.stepForm.phone && this.stepForm.phone.replace(/[\(\)\-]/g, '')
                        }
                    })
                } catch (errors) {
                    this.isSending = false
                    if (errors.field === 'phone' || errors.field === 'email') this.$emit('set-errors', errors)
                }
            }
        },
        prevStep() {
            this.$emit('prev-step', {
                step: 2,
                form: {
                    ...this.stepForm,
                    phone: this.stepForm.phone && this.stepForm.phone.replace(/[\(\)\-]/g, '')
                }
            })
        }
    }
}
</script>
